const journals = ref([
  { value: "Factu", key: "Invoices" },
  { value: "Ncred", key: "Credit notes" },
  { value: "PDown", key: "Pricedowns" },
  { value: "Comm", key: "Orders" },
  { value: "Blivr", key: "Shipping notes" },
  { value: "Plsng", key: "Pulsings" },
  { value: "Ncsav", key: "RMA Credit note" },
  { value: "Ncorr", key: "Price correction credit note" },
]);
const privateProductsMenus = ref({
  replenished: true,
});

const journalValues = ref([
  "Factu",
  "Ncred",
  "PDown",
  "Comm",
  "Blivr",
  "Plsng",
  "Ncsav",
  "Ncorr",
]);
const productsMenu = ref([
  {
    key: "hot_deals",
    path: "/products/search",
    query: {
      attribute: { price_promo: { "price promo": true } },
      sort: ["STOCK_DESC"],
    },
    img: "/menu/hotdeals.png",
  },
  {
    key: "announcements",
    path: "/products/search",
    query: {
      attribute: { status_preorder: { "status preorder": true } },
      sort: [{ Created: { order: "desc" } }, { Updated: { order: "desc" } }],
    },
    img: "/menu/announcements.png",
  },
  {
    key: "arriving_soon", //new arrival
    path: "/products/search",
    query: {
      attribute: {
        // status_preorder: { "status preorder": true },
        in_stock: { "in stock": true },
      },
      sort: [{ "Values.first_release_date.Data": { order: "desc" } }],
    },
    img: "/menu/arrivingsoon.png",
  },
  // ....................
  // {
  //   key: "New_Pre-Orders", //new Pre-Orders
  //   path: "/products/search",
  //   query: {
  //     attribute: {
  //       // status_preorder: { "status preorder": true },
  //       in_stock: { "in stock": true },
  //     },
  //     sort: [{ "Values.first_release_date.Data": { order: "desc" } }],
  //   },
  //   img: "/menu/lastunits1.png",
  // },
  // .....................
  {
    key: "in_stock",
    path: "/products/search",
    query: { attribute: { in_stock: { "in stock": true } }, sort: [] },
    img: "/menu/stock.png",
  },
  {
    key: "last_unit",
    path: "/products/search",
    query: {
      attribute: {
        stock_range: {
          lte: 29,
          gte: 1,
        },
      },
      sort: [],
    },
    img: "/menu/lastunits.png",
  },
  {
    key: "replenished",
    path: "/products/search",
    query: { attribute: { stock: { replenished: true } }, sort: [] },
    img: "/menu/replenished.png",
  },
]);

const CLD_CAT_PIM_CAT = {
  "ZY8J0G5N26": "cld_206",
  "AYAMYOMX1Y": "cld_564",
  "C8C42VNIED": "cld_398",
  "9C37C4FNX7": "cld_565",
  "MNZKLE8ERA": "cld_387",
  "I4XVJXU8ZI": "cld_846",
  "U159FLCOAN": "cld_878",
  "K05CDIXCZJ": "cld_266",
}


// TODO DELETE
const countries = ref([
  { Iso: "AF", Name: "AFGHANISTAN ", isEU: false },
  { Iso: "AL", Name: "ALBANIA ", isEU: false },
  { Iso: "DZ", Name: "ALGERIA ", isEU: false },
  { Iso: "AS", Name: "AMERICAN SAMOA ", isEU: false },
  { Iso: "AD", Name: "ANDORRA ", isEU: false },
  { Iso: "AO", Name: "ANGOLA ", isEU: false },
  { Iso: "AI", Name: "ANGUILLA ", isEU: false },
  { Iso: "AQ", Name: "ANTARCTICA ", isEU: false },
  { Iso: "AG", Name: "ANTIGUA AND BARBUDA ", isEU: false },
  { Iso: "AR", Name: "ARGENTINA ", isEU: false },
  { Iso: "AM", Name: "ARMENIA ", isEU: false },
  { Iso: "AW", Name: "ARUBA ", isEU: false },
  { Iso: "AU", Name: "AUSTRALIA ", isEU: false },
  { Iso: "AT", Name: "AUSTRIA ", isEU: true },
  { Iso: "AZ", Name: "AZERBAIJAN ", isEU: false },
  { Iso: "BS", Name: "BAHAMAS ", isEU: false },
  { Iso: "BH", Name: "BAHRAIN ", isEU: false },
  { Iso: "BD", Name: "BANGLADESH ", isEU: false },
  { Iso: "BB", Name: "BARBADOS ", isEU: false },
  { Iso: "BY", Name: "BELARUS ", isEU: false },
  { Iso: "BE", Name: "BELGIUM ", isEU: true },
  { Iso: "BZ", Name: "BELIZE ", isEU: false },
  { Iso: "BJ", Name: "BENIN ", isEU: false },
  { Iso: "BM", Name: "BERMUDA ", isEU: false },
  { Iso: "BT", Name: "BHUTAN ", isEU: false },
  { Iso: "BO", Name: "BOLIVIA ", isEU: false },
  { Iso: "BA", Name: "BOSNIA AND HERZEGOVINA ", isEU: false },
  { Iso: "BW", Name: "BOTSWANA ", isEU: false },
  { Iso: "BV", Name: "BOUVET ISLAND ", isEU: false },
  { Iso: "BR", Name: "BRAZIL ", isEU: false },
  { Iso: "IO", Name: "BRITISH INDIAN OCEAN TERRITORY ", isEU: false },
  { Iso: "BN", Name: "BRUNEI DARUSSALAM ", isEU: false },
  { Iso: "BG", Name: "BULGARIA ", isEU: true },
  { Iso: "BF", Name: "BURKINA FASO ", isEU: false },
  { Iso: "BI", Name: "BURUNDI ", isEU: false },
  { Iso: "KH", Name: "CAMBODIA ", isEU: false },
  { Iso: "CM", Name: "CAMEROON ", isEU: false },
  { Iso: "CA", Name: "CANADA ", isEU: false },
  { Iso: "IC", Name: "CANARY ISLANDS ", isEU: false },
  { Iso: "CV", Name: "CAPE VERDE ", isEU: false },
  { Iso: "KY", Name: "CAYMAN ISLANDS ", isEU: false },
  { Iso: "CF", Name: "CENTRAL AFRICAN REPUBLIC ", isEU: false },
  { Iso: "TD", Name: "CHAD ", isEU: false },
  { Iso: "CL", Name: "CHILE ", isEU: false },
  { Iso: "CN", Name: "CHINA ", isEU: false },
  { Iso: "CX", Name: "CHRISTMAS ISLAND ", isEU: false },
  { Iso: "CC", Name: "COCOS (KEELING) ISLANDS ", isEU: false },
  { Iso: "CO", Name: "COLOMBIA ", isEU: false },
  { Iso: "KM", Name: "COMOROS ", isEU: false },
  { Iso: "CG", Name: "CONGO ", isEU: false },
  { Iso: "CD", Name: "CONGO, THE DEMOCRATIC REPUBLIC OF THE ", isEU: false },
  { Iso: "CK", Name: "COOK ISLANDS ", isEU: false },
  { Iso: "CR", Name: "COSTA RICA ", isEU: false },
  { Iso: "CI", Name: "COTE D'IVOIRE ", isEU: false },
  { Iso: "HR", Name: "CROATIA ", isEU: true },
  { Iso: "CU", Name: "CUBA ", isEU: false },
  { Iso: "CY", Name: "CYPRUS ", isEU: true },
  { Iso: "CZ", Name: "CZECH REPUBLIC ", isEU: true },
  { Iso: "DK", Name: "DENMARK ", isEU: true },
  { Iso: "DJ", Name: "DJIBOUTI ", isEU: false },
  { Iso: "DM", Name: "DOMINICA ", isEU: false },
  { Iso: "DO", Name: "DOMINICAN REPUBLIC ", isEU: false },
  { Iso: "EC", Name: "ECUADOR ", isEU: false },
  { Iso: "EG", Name: "EGYPT ", isEU: false },
  { Iso: "SV", Name: "EL SALVADOR ", isEU: false },
  { Iso: "GQ", Name: "EQUATORIAL GUINEA ", isEU: false },
  { Iso: "ER", Name: "ERITREA ", isEU: false },
  { Iso: "EE", Name: "ESTONIA ", isEU: true },
  { Iso: "ET", Name: "ETHIOPIA ", isEU: false },
  { Iso: "FK", Name: "FALKLAND ISLANDS (MALVINAS) ", isEU: false },
  { Iso: "FO", Name: "FAROE ISLANDS ", isEU: false },
  { Iso: "FJ", Name: "FIJI ", isEU: false },
  { Iso: "FI", Name: "FINLAND ", isEU: true },
  { Iso: "FR", Name: "FRANCE ", isEU: true },
  { Iso: "GF", Name: "FRENCH GUIANA ", isEU: false },
  { Iso: "PF", Name: "FRENCH POLYNESIA ", isEU: false },
  { Iso: "TF", Name: "FRENCH SOUTHERN TERRITORIES ", isEU: false },
  { Iso: "GA", Name: "GABON ", isEU: false },
  { Iso: "GM", Name: "GAMBIA ", isEU: false },
  { Iso: "GE", Name: "GEORGIA ", isEU: false },
  { Iso: "DE", Name: "GERMANY ", isEU: true },
  { Iso: "GH", Name: "GHANA ", isEU: false },
  { Iso: "GI", Name: "GIBRALTAR ", isEU: false },
  { Iso: "GR", Name: "GREECE ", isEU: false },
  { Iso: "GL", Name: "GREENLAND ", isEU: false },
  { Iso: "GD", Name: "GRENADA ", isEU: false },
  { Iso: "GP", Name: "GUADELOUPE ", isEU: false },
  { Iso: "GU", Name: "GUAM ", isEU: false },
  { Iso: "GT", Name: "GUATEMALA ", isEU: false },
  { Iso: "GN", Name: "GUINEA ", isEU: false },
  { Iso: "GW", Name: "GUINEA-BISSAU ", isEU: false },
  { Iso: "GY", Name: "GUYANA ", isEU: false },
  { Iso: "HT", Name: "HAITI ", isEU: false },
  { Iso: "HM", Name: "HEARD ISLAND AND MCDONALD ISLANDS ", isEU: false },
  { Iso: "VA", Name: "HOLY SEE (VATICAN CITY STATE) ", isEU: false },
  { Iso: "HN", Name: "HONDURAS ", isEU: false },
  { Iso: "HK", Name: "HONG KONG ", isEU: false },
  { Iso: "HU", Name: "HUNGARY ", isEU: true },
  { Iso: "IS", Name: "ICELAND ", isEU: false },
  { Iso: "IN", Name: "INDIA ", isEU: false },
  { Iso: "ID", Name: "INDONESIA ", isEU: false },
  { Iso: "IR", Name: "IRAN, ISLAMIC REPUBLIC OF ", isEU: false },
  { Iso: "IQ", Name: "IRAQ ", isEU: false },
  { Iso: "IE", Name: "IRELAND ", isEU: true },
  { Iso: "IL", Name: "ISRAEL ", isEU: false },
  { Iso: "IT", Name: "ITALY ", isEU: true },
  { Iso: "JM", Name: "JAMAICA ", isEU: false },
  { Iso: "JP", Name: "JAPAN ", isEU: false },
  { Iso: "JO", Name: "JORDAN ", isEU: false },
  { Iso: "KZ", Name: "KAZAKHSTAN ", isEU: false },
  { Iso: "KE", Name: "KENYA ", isEU: false },
  { Iso: "KI", Name: "KIRIBATI ", isEU: false },
  { Iso: "KP", Name: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF ", isEU: false },
  { Iso: "KR", Name: "KOREA, REPUBLIC OF ", isEU: false },
  { Iso: "KW", Name: "KUWAIT ", isEU: false },
  { Iso: "KG", Name: "KYRGYZSTAN ", isEU: false },
  { Iso: "LA", Name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC ", isEU: false },
  { Iso: "LV", Name: "LATVIA ", isEU: true },
  { Iso: "LB", Name: "LEBANON ", isEU: false },
  { Iso: "LS", Name: "LESOTHO ", isEU: false },
  { Iso: "LR", Name: "LIBERIA ", isEU: false },
  { Iso: "LY", Name: "LIBYAN ARAB JAMAHIRIYA ", isEU: false },
  { Iso: "LI", Name: "LIECHTENSTEIN ", isEU: false },
  { Iso: "LT", Name: "LITHUANIA ", isEU: true },
  { Iso: "LU", Name: "LUXEMBOURG ", isEU: true },
  { Iso: "MO", Name: "MACAO ", isEU: false },
  {
    Iso: "MK",
    Name: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF ",
    isEU: false,
  },
  { Iso: "MG", Name: "MADAGASCAR ", isEU: false },
  { Iso: "MW", Name: "MALAWI ", isEU: false },
  { Iso: "MY", Name: "MALAYSIA ", isEU: false },
  { Iso: "MV", Name: "MALDIVES ", isEU: false },
  { Iso: "ML", Name: "MALI ", isEU: false },
  { Iso: "MT", Name: "MALTA ", isEU: true },
  { Iso: "MH", Name: "MARSHALL ISLANDS ", isEU: false },
  { Iso: "MQ", Name: "MARTINIQUE ", isEU: false },
  { Iso: "MR", Name: "MAURITANIA ", isEU: false },
  { Iso: "MU", Name: "MAURITIUS ", isEU: false },
  { Iso: "YT", Name: "MAYOTTE ", isEU: false },
  { Iso: "MX", Name: "MEXICO ", isEU: false },
  { Iso: "FM", Name: "MICRONESIA, FEDERATED STATES OF ", isEU: false },
  { Iso: "MD", Name: "MOLDOVA, REPUBLIC OF ", isEU: false },
  { Iso: "MC", Name: "MONACO ", isEU: false },
  { Iso: "MN", Name: "MONGOLIA ", isEU: false },
  { Iso: "MS", Name: "MONTSERRAT ", isEU: false },
  { Iso: "MA", Name: "MOROCCO ", isEU: false },
  { Iso: "MZ", Name: "MOZAMBIQUE ", isEU: false },
  { Iso: "MM", Name: "MYANMAR ", isEU: false },
  { Iso: "NA", Name: "NAMIBIA ", isEU: false },
  { Iso: "NR", Name: "NAURU ", isEU: false },
  { Iso: "NP", Name: "NEPAL ", isEU: false },
  { Iso: "NL", Name: "NETHERLANDS ", isEU: true },
  { Iso: "AN", Name: "NETHERLANDS ANTILLES ", isEU: false },
  { Iso: "NC", Name: "NEW CALEDONIA ", isEU: false },
  { Iso: "NZ", Name: "NEW ZEALAND ", isEU: false },
  { Iso: "NI", Name: "NICARAGUA ", isEU: false },
  { Iso: "NE", Name: "NIGER ", isEU: false },
  { Iso: "NG", Name: "NIGERIA ", isEU: false },
  { Iso: "NU", Name: "NIUE ", isEU: false },
  { Iso: "NF", Name: "NORFOLK ISLAND ", isEU: false },
  { Iso: "MP", Name: "NORTHERN MARIANA ISLANDS ", isEU: false },
  { Iso: "NO", Name: "NORWAY ", isEU: false },
  { Iso: "OM", Name: "OMAN ", isEU: false },
  { Iso: "PK", Name: "PAKISTAN ", isEU: false },
  { Iso: "PW", Name: "PALAU ", isEU: false },
  { Iso: "PS", Name: "PALESTINIAN TERRITORY, OCCUPIED ", isEU: false },
  { Iso: "PA", Name: "PANAMA ", isEU: false },
  { Iso: "PG", Name: "PAPUA NEW GUINEA ", isEU: false },
  { Iso: "PY", Name: "PARAGUAY ", isEU: false },
  { Iso: "PE", Name: "PERU ", isEU: false },
  { Iso: "PH", Name: "PHILIPPINES ", isEU: false },
  { Iso: "PN", Name: "PITCAIRN ", isEU: false },
  { Iso: "PL", Name: "POLAND ", isEU: true },
  { Iso: "PT", Name: "PORTUGAL ", isEU: true },
  { Iso: "PR", Name: "PUERTO RICO ", isEU: false },
  { Iso: "QA", Name: "QATAR ", isEU: false },
  { Iso: "RE", Name: "REUNION ", isEU: false },
  { Iso: "RO", Name: "ROMANIA ", isEU: true },
  { Iso: "RU", Name: "RUSSIAN FEDERATION ", isEU: false },
  { Iso: "RW", Name: "RWANDA ", isEU: false },
  { Iso: "SH", Name: "SAINT HELENA ", isEU: false },
  { Iso: "KN", Name: "SAINT KITTS AND NEVIS ", isEU: false },
  { Iso: "LC", Name: "SAINT LUCIA ", isEU: false },
  { Iso: "PM", Name: "SAINT PIERRE AND MIQUELON ", isEU: false },
  { Iso: "VC", Name: "SAINT VINCENT AND THE GRENADINES ", isEU: false },
  { Iso: "MF", Name: "SAINT-MARTIN ", isEU: false },
  { Iso: "WS", Name: "SAMOA ", isEU: false },
  { Iso: "SM", Name: "SAN MARINO ", isEU: false },
  { Iso: "ST", Name: "SAO TOME AND PRINCIPE ", isEU: false },
  { Iso: "SA", Name: "SAUDI ARABIA ", isEU: false },
  { Iso: "SN", Name: "SENEGAL ", isEU: false },
  { Iso: "CS", Name: "SERBIA AND MONTENEGRO ", isEU: false },
  { Iso: "SC", Name: "SEYCHELLES ", isEU: false },
  { Iso: "SL", Name: "SIERRA LEONE ", isEU: false },
  { Iso: "SG", Name: "SINGAPORE ", isEU: false },
  { Iso: "SK", Name: "SLOVAKIA ", isEU: true },
  { Iso: "SI", Name: "SLOVENIA ", isEU: true },
  { Iso: "SB", Name: "SOLOMON ISLANDS ", isEU: false },
  { Iso: "SO", Name: "SOMALIA ", isEU: false },
  { Iso: "ZA", Name: "SOUTH AFRICA ", isEU: false },
  {
    Iso: "GS",
    Name: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS ",
    isEU: false,
  },
  { Iso: "ES", Name: "SPAIN ", isEU: true },
  { Iso: "LK", Name: "SRI LANKA ", isEU: false },
  { Iso: "SD", Name: "SUDAN ", isEU: false },
  { Iso: "SR", Name: "SURINAME ", isEU: false },
  { Iso: "SJ", Name: "SVALBARD AND JAN MAYEN ", isEU: false },
  { Iso: "SZ", Name: "SWAZILAND ", isEU: false },
  { Iso: "SE", Name: "SWEDEN ", isEU: true },
  { Iso: "CH", Name: "SWITZERLAND ", isEU: false },
  { Iso: "SY", Name: "SYRIAN ARAB REPUBLIC ", isEU: false },
  { Iso: "TW", Name: "TAIWAN, PROVINCE OF CHINA ", isEU: false },
  { Iso: "TJ", Name: "TAJIKISTAN ", isEU: false },
  { Iso: "TZ", Name: "TANZANIA, UNITED REPUBLIC OF ", isEU: false },
  { Iso: "TH", Name: "THAILAND ", isEU: false },
  { Iso: "TL", Name: "TIMOR-LESTE ", isEU: false },
  { Iso: "TG", Name: "TOGO ", isEU: false },
  { Iso: "TK", Name: "TOKELAU ", isEU: false },
  { Iso: "TO", Name: "TONGA ", isEU: false },
  { Iso: "TT", Name: "TRINIDAD AND TOBAGO ", isEU: false },
  { Iso: "TN", Name: "TUNISIA ", isEU: false },
  { Iso: "TR", Name: "TURKEY ", isEU: false },
  { Iso: "TM", Name: "TURKMENISTAN ", isEU: false },
  { Iso: "TC", Name: "TURKS AND CAICOS ISLANDS ", isEU: false },
  { Iso: "TV", Name: "TUVALU ", isEU: false },
  { Iso: "UG", Name: "UGANDA ", isEU: false },
  { Iso: "UA", Name: "UKRAINE ", isEU: false },
  { Iso: "AE", Name: "UNITED ARAB EMIRATES ", isEU: false },
  { Iso: "GB", Name: "UNITED KINGDOM ", isEU: false },
  { Iso: "US", Name: "UNITED STATES ", isEU: false },
  { Iso: "UM", Name: "UNITED STATES MINOR OUTLYING ISLANDS ", isEU: false },
  { Iso: "UY", Name: "URUGUAY ", isEU: false },
  { Iso: "UZ", Name: "UZBEKISTAN ", isEU: false },
  { Iso: "VU", Name: "VANUATU ", isEU: false },
  { Iso: "VE", Name: "VENEZUELA ", isEU: false },
  { Iso: "VN", Name: "VIET NAM ", isEU: false },
  { Iso: "VG", Name: "VIRGIN ISLANDS, BRITISH ", isEU: false },
  { Iso: "VI", Name: "VIRGIN ISLANDS, U.S. ", isEU: false },
  { Iso: "WF", Name: "WALLIS AND FUTUNA ", isEU: false },
  { Iso: "EH", Name: "WESTERN SAHARA ", isEU: false },
  { Iso: "YE", Name: "YEMEN ", isEU: false },
  { Iso: "ZM", Name: "ZAMBIA ", isEU: false },
  { Iso: "ZW", Name: "ZIMBABWE ", isEU: false },
]);

const countriesV2 = ref([
  { value: "AF", title: "AFGHANISTAN ", isEU: false },
  { value: "AL", title: "ALBANIA ", isEU: false },
  { value: "DZ", title: "ALGERIA ", isEU: false },
  { value: "AS", title: "AMERICAN SAMOA ", isEU: false },
  { value: "AD", title: "ANDORRA ", isEU: false },
  { value: "AO", title: "ANGOLA ", isEU: false },
  { value: "AI", title: "ANGUILLA ", isEU: false },
  { value: "AQ", title: "ANTARCTICA ", isEU: false },
  { value: "AG", title: "ANTIGUA AND BARBUDA ", isEU: false },
  { value: "AR", title: "ARGENTINA ", isEU: false },
  { value: "AM", title: "ARMENIA ", isEU: false },
  { value: "AW", title: "ARUBA ", isEU: false },
  { value: "AU", title: "AUSTRALIA ", isEU: false },
  { value: "AT", title: "AUSTRIA ", isEU: true },
  { value: "AZ", title: "AZERBAIJAN ", isEU: false },
  { value: "BS", title: "BAHAMAS ", isEU: false },
  { value: "BH", title: "BAHRAIN ", isEU: false },
  { value: "BD", title: "BANGLADESH ", isEU: false },
  { value: "BB", title: "BARBADOS ", isEU: false },
  { value: "BY", title: "BELARUS ", isEU: false },
  { value: "BE", title: "BELGIUM ", isEU: true },
  { value: "BZ", title: "BELIZE ", isEU: false },
  { value: "BJ", title: "BENIN ", isEU: false },
  { value: "BM", title: "BERMUDA ", isEU: false },
  { value: "BT", title: "BHUTAN ", isEU: false },
  { value: "BO", title: "BOLIVIA ", isEU: false },
  { value: "BA", title: "BOSNIA AND HERZEGOVINA ", isEU: false },
  { value: "BW", title: "BOTSWANA ", isEU: false },
  { value: "BV", title: "BOUVET ISLAND ", isEU: false },
  { value: "BR", title: "BRAZIL ", isEU: false },
  { value: "IO", title: "BRITISH INDIAN OCEAN TERRITORY ", isEU: false },
  { value: "BN", title: "BRUNEI DARUSSALAM ", isEU: false },
  { value: "BG", title: "BULGARIA ", isEU: true },
  { value: "BF", title: "BURKINA FASO ", isEU: false },
  { value: "BI", title: "BURUNDI ", isEU: false },
  { value: "KH", title: "CAMBODIA ", isEU: false },
  { value: "CM", title: "CAMEROON ", isEU: false },
  { value: "CA", title: "CANADA ", isEU: false },
  { value: "IC", title: "CANARY ISLANDS ", isEU: false },
  { value: "CV", title: "CAPE VERDE ", isEU: false },
  { value: "KY", title: "CAYMAN ISLANDS ", isEU: false },
  { value: "CF", title: "CENTRAL AFRICAN REPUBLIC ", isEU: false },
  { value: "TD", title: "CHAD ", isEU: false },
  { value: "CL", title: "CHILE ", isEU: false },
  { value: "CN", title: "CHINA ", isEU: false },
  { value: "CX", title: "CHRISTMAS ISLAND ", isEU: false },
  { value: "CC", title: "COCOS (KEELING) ISLANDS ", isEU: false },
  { value: "CO", title: "COLOMBIA ", isEU: false },
  { value: "KM", title: "COMOROS ", isEU: false },
  { value: "CG", title: "CONGO ", isEU: false },
  { value: "CD", title: "CONGO, THE DEMOCRATIC REPUBLIC OF THE ", isEU: false },
  { value: "CK", title: "COOK ISLANDS ", isEU: false },
  { value: "CR", title: "COSTA RICA ", isEU: false },
  { value: "CI", title: "COTE D'IVOIRE ", isEU: false },
  { value: "HR", title: "CROATIA ", isEU: true },
  { value: "CU", title: "CUBA ", isEU: false },
  { value: "CY", title: "CYPRUS ", isEU: true },
  { value: "CZ", title: "CZECH REPUBLIC ", isEU: true },
  { value: "DK", title: "DENMARK ", isEU: true },
  { value: "DJ", title: "DJIBOUTI ", isEU: false },
  { value: "DM", title: "DOMINICA ", isEU: false },
  { value: "DO", title: "DOMINICAN REPUBLIC ", isEU: false },
  { value: "EC", title: "ECUADOR ", isEU: false },
  { value: "EG", title: "EGYPT ", isEU: false },
  { value: "SV", title: "EL SALVADOR ", isEU: false },
  { value: "GQ", title: "EQUATORIAL GUINEA ", isEU: false },
  { value: "ER", title: "ERITREA ", isEU: false },
  { value: "EE", title: "ESTONIA ", isEU: true },
  { value: "ET", title: "ETHIOPIA ", isEU: false },
  { value: "FK", title: "FALKLAND ISLANDS (MALVINAS) ", isEU: false },
  { value: "FO", title: "FAROE ISLANDS ", isEU: false },
  { value: "FJ", title: "FIJI ", isEU: false },
  { value: "FI", title: "FINLAND ", isEU: true },
  { value: "FR", title: "FRANCE ", isEU: true },
  { value: "GF", title: "FRENCH GUIANA ", isEU: false },
  { value: "PF", title: "FRENCH POLYNESIA ", isEU: false },
  { value: "TF", title: "FRENCH SOUTHERN TERRITORIES ", isEU: false },
  { value: "GA", title: "GABON ", isEU: false },
  { value: "GM", title: "GAMBIA ", isEU: false },
  { value: "GE", title: "GEORGIA ", isEU: false },
  { value: "DE", title: "GERMANY ", isEU: true },
  { value: "GH", title: "GHANA ", isEU: false },
  { value: "GI", title: "GIBRALTAR ", isEU: false },
  { value: "GR", title: "GREECE ", isEU: false },
  { value: "GL", title: "GREENLAND ", isEU: false },
  { value: "GD", title: "GRENADA ", isEU: false },
  { value: "GP", title: "GUADELOUPE ", isEU: false },
  { value: "GU", title: "GUAM ", isEU: false },
  { value: "GT", title: "GUATEMALA ", isEU: false },
  { value: "GN", title: "GUINEA ", isEU: false },
  { value: "GW", title: "GUINEA-BISSAU ", isEU: false },
  { value: "GY", title: "GUYANA ", isEU: false },
  { value: "HT", title: "HAITI ", isEU: false },
  { value: "HM", title: "HEARD ISLAND AND MCDONALD ISLANDS ", isEU: false },
  { value: "VA", title: "HOLY SEE (VATICAN CITY STATE) ", isEU: false },
  { value: "HN", title: "HONDURAS ", isEU: false },
  { value: "HK", title: "HONG KONG ", isEU: false },
  { value: "HU", title: "HUNGARY ", isEU: true },
  { value: "IS", title: "ICELAND ", isEU: false },
  { value: "IN", title: "INDIA ", isEU: false },
  { value: "ID", title: "INDONESIA ", isEU: false },
  { value: "IR", title: "IRAN, ISLAMIC REPUBLIC OF ", isEU: false },
  { value: "IQ", title: "IRAQ ", isEU: false },
  { value: "IE", title: "IRELAND ", isEU: true },
  { value: "IL", title: "ISRAEL ", isEU: false },
  { value: "IT", title: "ITALY ", isEU: true },
  { value: "JM", title: "JAMAICA ", isEU: false },
  { value: "JP", title: "JAPAN ", isEU: false },
  { value: "JO", title: "JORDAN ", isEU: false },
  { value: "KZ", title: "KAZAKHSTAN ", isEU: false },
  { value: "KE", title: "KENYA ", isEU: false },
  { value: "KI", title: "KIRIBATI ", isEU: false },
  {
    value: "KP",
    title: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF ",
    isEU: false,
  },
  { value: "KR", title: "KOREA, REPUBLIC OF ", isEU: false },
  { value: "KW", title: "KUWAIT ", isEU: false },
  { value: "KG", title: "KYRGYZSTAN ", isEU: false },
  { value: "LA", title: "LAO PEOPLE'S DEMOCRATIC REPUBLIC ", isEU: false },
  { value: "LV", title: "LATVIA ", isEU: true },
  { value: "LB", title: "LEBANON ", isEU: false },
  { value: "LS", title: "LESOTHO ", isEU: false },
  { value: "LR", title: "LIBERIA ", isEU: false },
  { value: "LY", title: "LIBYAN ARAB JAMAHIRIYA ", isEU: false },
  { value: "LI", title: "LIECHTENSTEIN ", isEU: false },
  { value: "LT", title: "LITHUANIA ", isEU: true },
  { value: "LU", title: "LUXEMBOURG ", isEU: true },
  { value: "MO", title: "MACAO ", isEU: false },
  {
    value: "MK",
    title: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF ",
    isEU: false,
  },
  { value: "MG", title: "MADAGASCAR ", isEU: false },
  { value: "MW", title: "MALAWI ", isEU: false },
  { value: "MY", title: "MALAYSIA ", isEU: false },
  { value: "MV", title: "MALDIVES ", isEU: false },
  { value: "ML", title: "MALI ", isEU: false },
  { value: "MT", title: "MALTA ", isEU: true },
  { value: "MH", title: "MARSHALL ISLANDS ", isEU: false },
  { value: "MQ", title: "MARTINIQUE ", isEU: false },
  { value: "MR", title: "MAURITANIA ", isEU: false },
  { value: "MU", title: "MAURITIUS ", isEU: false },
  { value: "YT", title: "MAYOTTE ", isEU: false },
  { value: "MX", title: "MEXICO ", isEU: false },
  { value: "FM", title: "MICRONESIA, FEDERATED STATES OF ", isEU: false },
  { value: "MD", title: "MOLDOVA, REPUBLIC OF ", isEU: false },
  { value: "MC", title: "MONACO ", isEU: false },
  { value: "MN", title: "MONGOLIA ", isEU: false },
  { value: "MS", title: "MONTSERRAT ", isEU: false },
  { value: "MA", title: "MOROCCO ", isEU: false },
  { value: "MZ", title: "MOZAMBIQUE ", isEU: false },
  { value: "MM", title: "MYANMAR ", isEU: false },
  { value: "NA", title: "NAMIBIA ", isEU: false },
  { value: "NR", title: "NAURU ", isEU: false },
  { value: "NP", title: "NEPAL ", isEU: false },
  { value: "NL", title: "NETHERLANDS ", isEU: true },
  { value: "AN", title: "NETHERLANDS ANTILLES ", isEU: false },
  { value: "NC", title: "NEW CALEDONIA ", isEU: false },
  { value: "NZ", title: "NEW ZEALAND ", isEU: false },
  { value: "NI", title: "NICARAGUA ", isEU: false },
  { value: "NE", title: "NIGER ", isEU: false },
  { value: "NG", title: "NIGERIA ", isEU: false },
  { value: "NU", title: "NIUE ", isEU: false },
  { value: "NF", title: "NORFOLK ISLAND ", isEU: false },
  { value: "MP", title: "NORTHERN MARIANA ISLANDS ", isEU: false },
  { value: "NO", title: "NORWAY ", isEU: false },
  { value: "OM", title: "OMAN ", isEU: false },
  { value: "PK", title: "PAKISTAN ", isEU: false },
  { value: "PW", title: "PALAU ", isEU: false },
  { value: "PS", title: "PALESTINIAN TERRITORY, OCCUPIED ", isEU: false },
  { value: "PA", title: "PANAMA ", isEU: false },
  { value: "PG", title: "PAPUA NEW GUINEA ", isEU: false },
  { value: "PY", title: "PARAGUAY ", isEU: false },
  { value: "PE", title: "PERU ", isEU: false },
  { value: "PH", title: "PHILIPPINES ", isEU: false },
  { value: "PN", title: "PITCAIRN ", isEU: false },
  { value: "PL", title: "POLAND ", isEU: true },
  { value: "PT", title: "PORTUGAL ", isEU: true },
  { value: "PR", title: "PUERTO RICO ", isEU: false },
  { value: "QA", title: "QATAR ", isEU: false },
  { value: "RE", title: "REUNION ", isEU: false },
  { value: "RO", title: "ROMANIA ", isEU: true },
  { value: "RU", title: "RUSSIAN FEDERATION ", isEU: false },
  { value: "RW", title: "RWANDA ", isEU: false },
  { value: "SH", title: "SAINT HELENA ", isEU: false },
  { value: "KN", title: "SAINT KITTS AND NEVIS ", isEU: false },
  { value: "LC", title: "SAINT LUCIA ", isEU: false },
  { value: "PM", title: "SAINT PIERRE AND MIQUELON ", isEU: false },
  { value: "VC", title: "SAINT VINCENT AND THE GRENADINES ", isEU: false },
  { value: "MF", title: "SAINT-MARTIN ", isEU: false },
  { value: "WS", title: "SAMOA ", isEU: false },
  { value: "SM", title: "SAN MARINO ", isEU: false },
  { value: "ST", title: "SAO TOME AND PRINCIPE ", isEU: false },
  { value: "SA", title: "SAUDI ARABIA ", isEU: false },
  { value: "SN", title: "SENEGAL ", isEU: false },
  { value: "CS", title: "SERBIA AND MONTENEGRO ", isEU: false },
  { value: "SC", title: "SEYCHELLES ", isEU: false },
  { value: "SL", title: "SIERRA LEONE ", isEU: false },
  { value: "SG", title: "SINGAPORE ", isEU: false },
  { value: "SK", title: "SLOVAKIA ", isEU: true },
  { value: "SI", title: "SLOVENIA ", isEU: true },
  { value: "SB", title: "SOLOMON ISLANDS ", isEU: false },
  { value: "SO", title: "SOMALIA ", isEU: false },
  { value: "ZA", title: "SOUTH AFRICA ", isEU: false },
  {
    value: "GS",
    title: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS ",
    isEU: false,
  },
  { value: "ES", title: "SPAIN ", isEU: true },
  { value: "LK", title: "SRI LANKA ", isEU: false },
  { value: "SD", title: "SUDAN ", isEU: false },
  { value: "SR", title: "SURINAME ", isEU: false },
  { value: "SJ", title: "SVALBARD AND JAN MAYEN ", isEU: false },
  { value: "SZ", title: "SWAZILAND ", isEU: false },
  { value: "SE", title: "SWEDEN ", isEU: true },
  { value: "CH", title: "SWITZERLAND ", isEU: false },
  { value: "SY", title: "SYRIAN ARAB REPUBLIC ", isEU: false },
  { value: "TW", title: "TAIWAN, PROVINCE OF CHINA ", isEU: false },
  { value: "TJ", title: "TAJIKISTAN ", isEU: false },
  { value: "TZ", title: "TANZANIA, UNITED REPUBLIC OF ", isEU: false },
  { value: "TH", title: "THAILAND ", isEU: false },
  { value: "TL", title: "TIMOR-LESTE ", isEU: false },
  { value: "TG", title: "TOGO ", isEU: false },
  { value: "TK", title: "TOKELAU ", isEU: false },
  { value: "TO", title: "TONGA ", isEU: false },
  { value: "TT", title: "TRINIDAD AND TOBAGO ", isEU: false },
  { value: "TN", title: "TUNISIA ", isEU: false },
  { value: "TR", title: "TURKEY ", isEU: false },
  { value: "TM", title: "TURKMENISTAN ", isEU: false },
  { value: "TC", title: "TURKS AND CAICOS ISLANDS ", isEU: false },
  { value: "TV", title: "TUVALU ", isEU: false },
  { value: "UG", title: "UGANDA ", isEU: false },
  { value: "UA", title: "UKRAINE ", isEU: false },
  { value: "AE", title: "UNITED ARAB EMIRATES ", isEU: false },
  { value: "GB", title: "UNITED KINGDOM ", isEU: false },
  { value: "US", title: "UNITED STATES ", isEU: false },
  { value: "UM", title: "UNITED STATES MINOR OUTLYING ISLANDS ", isEU: false },
  { value: "UY", title: "URUGUAY ", isEU: false },
  { value: "UZ", title: "UZBEKISTAN ", isEU: false },
  { value: "VU", title: "VANUATU ", isEU: false },
  { value: "VE", title: "VENEZUELA ", isEU: false },
  { value: "VN", title: "VIET NAM ", isEU: false },
  { value: "VG", title: "VIRGIN ISLANDS, BRITISH ", isEU: false },
  { value: "VI", title: "VIRGIN ISLANDS, U.S. ", isEU: false },
  { value: "WF", title: "WALLIS AND FUTUNA ", isEU: false },
  { value: "EH", title: "WESTERN SAHARA ", isEU: false },
  { value: "YE", title: "YEMEN ", isEU: false },
  { value: "ZM", title: "ZAMBIA ", isEU: false },
  { value: "ZW", title: "ZIMBABWE ", isEU: false },
]);

export {
  journals,
  journalValues,
  productsMenu,
  privateProductsMenus,
  countries, CLD_CAT_PIM_CAT,
  countriesV2
};
